import useGetOverwolfArt, { OverwolfArtType } from "@/hooks/useGetOverwolfArt";
import classNames from "classnames";

const OverwolfGameLogo = () => {
    const { getOverwolfArt, hasCustomLogo } = useGetOverwolfArt();

    return (
        <img
            className={classNames({
                "overwolf-game-logo": hasCustomLogo,
            })}
            src={getOverwolfArt(OverwolfArtType.devourLogo)}
            alt="Devour logo"
        />
    );
};

export default OverwolfGameLogo;
